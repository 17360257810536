$base-color: #387cf7;
$disable-background-color: #eceeef;
.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  .dropdown-btn {
    display: inline-block;
    border: 1px solid #adadad;
    width: 100%;
    padding: 6px 25px 6px 12px !important;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    height: 32px !important;
    background: white !important;
    .selected-item {
      border: 1px solid $base-color !important;
      margin-right: 4px;
      margin-top: -2px !important;
      background: $base-color !important;
      padding: 0px 5px;
      color: #fff;
      border-radius: 2px;
      float: left;
      a {
        text-decoration: none;
      }
    }
    .selected-item:hover {
      box-shadow: 1px 1px #959595;
    }
    .dropdown-down {
      display: inline-block;
      top: 0px !important;
      width: 0;
      height: 0;
      border-top: 10px solid black !important;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    .dropdown-up {
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 10px solid black !important;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      background-color: black !important;
    }

    .dropdown-multiselect__caret {
      padding-top: 0px !important;
      margin-top: -1px !important;
      transform: none !important;
    }

    .dropdown-multiselect__caret::before {
      border-color: black transparent transparent transparent !important;
      border-width: 8px 6px 0 6px !important;
    }
  }
  .disabled {
    & > span {
      background-color: $disable-background-color;
    }
  }
}

.no-data h5 {
  font-size: 13px !important;
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0px 1px 5px #959595;
  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
  }
  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }
  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;
    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }
    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox input[type="checkbox"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: contain !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"]:focus + div:before,
.multiselect-item-checkbox input[type="checkbox"]:hover + div:before {
  border-color: black !important;
  background-color: #f2f2f2;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"]:active + div:before {
  transition-duration: 0s !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  box-sizing: content-box;
  content: "";
  color: black !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 1px solid black !important;
  text-align: center;
  transition: none !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:after {
  box-sizing: content-box;
  content: "";
  background-color: transparent !important;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: none !important;
  transform-origin: 0% !important;
  transition: none !important;
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"]:disabled + div:before {
  border-color: #cccccc;
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox
  input[type="checkbox"]:disabled:focus
  + div:before
  .multiselect-item-checkbox
  input[type="checkbox"]:disabled:hover
  + div:before {
  background-color: inherit;
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox
  input[type="checkbox"]:disabled:checked
  + div:before {
  background-color: #cccccc;
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: transparent !important;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: none !important;
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:after {
  content: "";
  transform: none !important;
  transition: none !important;
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  animation: none !important;
  background: none !important;
  background-image: url("./assets/checkbox.png") !important;
  background-size: 17px 16px !important;
  background-position: -1.2px -1px !important;
  background-repeat: no-repeat !important;
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:after {
  transform: rotate(-45deg) scale(1);
  border: 1px solid transparent !important;
  -webkit-appearance: none !important;
}

@keyframes borderscale {
  50% {
    box-shadow: none !important;
  }
}
