/* You can add global styles to this file, and also import other style files */
.c_input {
  width: 28vw;
  padding: 8px 0px 12px 4px;
  margin-top: 15px;
  border: 1px solid black;
}

@media (max-width: 990px) {

  .c_input {
    width: 35vw;
    padding: 8px 0px 12px 4px;
    margin-top: 15px;
    border: 1px solid black;
  }
}

@media (max-width: 500px) {

  .c_input {
    width: 78vw;
    padding: 5px 0px 10px 4px;
    margin-top: 15px;
    border: 1px solid black;
  }
}

@media (max-width: 400px) {
  .multiselect-dropdown .dropdown-btn {
    display: inline-block !important;
    width: 100% !important;
    padding: 2px 4px !important;
    padding-top: 8px !important;
    margin-bottom: 0 !important;
    font-size: 10px !important;
    font-weight: 400 !important;

    text-align: left !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    background-image: none !important;
    border-radius: 4px !important;
  }

  .c_input {
    width: 76vw;
    padding: 5px 0px 10px 4px;
    margin-top: 15px;
    border: 1px solid black;
  }

  @media (max-width: 370px) {

    .c_input {
      width: 73vw;
      padding: 5px 0px 10px 4px;
      margin-top: 15px;
      border: 1px solid black;
    }
  }

  @media (max-width: 330px) {

    .multiselect-dropdown .dropdown-btn {
      display: inline-block !important;
      width: 100% !important;
      padding: 2px 4px !important;
      padding-top: 8px !important;
      margin-bottom: 0 !important;
      font-size: 8px !important;
      font-weight: 400 !important;

      text-align: left !important;
      vertical-align: middle !important;
      cursor: pointer !important;
      background-image: none !important;
      border-radius: 4px !important;
    }
  }
}